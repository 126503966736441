import React,{useEffect} from 'react';
import Styleclass from './Menustyle.module.css';
import ARButtonIcon from '../ARIcon.png';
import {resource} from '../resource';
import {
  OGMOCanvas,
  useEnvironmentStore,
  useSceneStore,
} from '../../ogmo-renderer';
import EnvHdr from './IBL_VS.hdr';

const NFTComponent3D = (props) => {
  const setEnvironmentMapExplicitPath = useEnvironmentStore(
    (state) => state.setEnvironmentMapExplicitPath
  );

  const setModelPath = useSceneStore((state) => state.setModelPath);

  useEffect(() => {
    setEnvironmentMapExplicitPath(EnvHdr);
    setModelPath(props.url);
  });

  return (
    <OGMOCanvas
      id={"abc"}
      glb_link={props.glb_link}
      usdz_link={props.usdz_link}
    />
  );
};

function MobileViewer(props) {

  const currentURL = window.location.href;
  const lastSegment = currentURL.split("/").pop();

  let glbPath;
  let usdzPath;

  resource.map(object => {
    if(object.name === lastSegment) {   
        glbPath = object.glbPath;
        usdzPath = object.usdzPath;
    }
})

    let platform = window.navigator.platform;
    let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    let os = null;
  
    if (/iPhone/g.test(navigator.userAgent)) {
      os = 'iphone';
    }
    else if (/iPad/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      os = 'ipad';
    }
    else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'windows';
    }
    else if (/android/i.test(navigator.userAgent)) {
      os = 'android';
    }
    else {
      os = 'device';
    }

    return (
        <div>
            <div style={{display:'flex', justifyContent:'center',height:'80vh'}}>
              <NFTComponent3D
                url={glbPath}
                glb_link={glbPath}
                usdz_link={usdzPath}
              />
          </div>
            {os === 'android' && <div style={{display:'flex',justifyContent:'center'}}>
                    <button className={Styleclass.arbutton}>
                        <a className={Styleclass.atag} href={`intent://arvr.google.com/scene-viewer/1.0?file=${glbPath}&mode=ar_only#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;end;`}>
                            <svg className={Styleclass.btnlogo} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
                            </svg>
                            <label className={Styleclass.btntext}>See in AR</label>
                        </a>
                    </button>
                </div>
                }

                {(os === 'iphone' || os === 'ipad' || os === 'windows') && <div style={{display:'flex',justifyContent:'center'}}>
                    <button className={Styleclass.arbutton}>
                        <a className={Styleclass.atag} href={usdzPath} rel="ar">
                            <svg className={Styleclass.btnlogo} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
                            </svg>
                            <label className={Styleclass.btntext}>See in AR</label>
                        </a>
                    </button>
                </div>
                }
        </div>
    );
}

export default MobileViewer;