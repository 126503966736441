import KotiSeaterGLB from './HemShowroom/glb/Koti_2-seater.glb';
import KotiSeaterUSDZ from './HemShowroom/usdz/usdzkoti2seater.usdz';
import CoffeeTableGLB  from  './HemShowroom/glb/Alle_Coffee_Table.glb';
import LoungeChairGLB from  './HemShowroom/glb/Hem_Puffy_LoungeChair.glb';
import ChairUpholdGLB  from  './HemShowroom/glb/Hem_Udon_ChairUpholstered.glb';
import MaxTableGLB from  './HemShowroom/glb/MaxTable.glb';
import ChairUpholdUSDZ  from './HemShowroom/usdz/Hem_Udon_Chairupholstered.usdz';
import MaxTableUSDZ  from './HemShowroom/usdz/maxtable.usdz';
import LoungeChairUSDZ  from './HemShowroom/usdz/Puffy_LoungeChairUSDZ.usdz';
import CoffeeTableUSDZ from './HemShowroom/usdz/usdz_alle_coffee_table.usdz';

export const resource = [
    {
        name: "VlinderSofa",
        glbPath: KotiSeaterGLB,
        usdzPath: KotiSeaterUSDZ,
    },
    {
        name: "Resting_Bear",
        glbPath: "",
        usdzPath: "",
    },
    {
        name: "Resting_Bear1",
        glbPath: "",
        usdzPath: "",
    },
    {
        name: "Table_Items",
        glbPath: "",
        usdzPath: "",
    },
    {
        name: "Plate_Table_Top",
        glbPath: CoffeeTableGLB,
        usdzPath: CoffeeTableUSDZ,
    },
    {
        name: "Tabale_Top",
        glbPath: MaxTableGLB,
        usdzPath: MaxTableUSDZ,
    },
]