import create from 'zustand'

let environmentStore = (set) => ({
    environmentMap: "",
    environmentMapExplicitPath :"",
    backgroundColor : '#ffffff',
    ambientIntensity : 1.0,
    shadowOpacity : 0.5,
    shadowBlur : 0.5,
    shadowBias : 0.0,
    toggleUseBackgroundColor: () => set((state) => ({ useBackgroundColor: !state.useBackgroundColor })),
    setBackgroundColor: (color) => set((state) => ({ backgroundColor: color })),
    setEnvironmentMap: (id) => set((state) => ({ environmentMap: id })),
    setEnvironmentMapExplicitPath: (path) => set((state) => ({ environmentMapExplicitPath: path })),
    setAmbientIntensity: (intensity) => set((state) => ({ ambientIntensity: intensity })),
    setShadowOpacity: (opacity) => set((state) => ({ shadowOpacity: opacity })),
    setShadowBlur: (blur) => set((state) => ({ shadowBlur: blur })),
    setShadowBias: (bias) => set((state) => ({ shadowBias: bias }))
})

let sceneStore = (set) => ({
    modelPath: "",
    modelCenter: {x: 0, y: 0, z: 0},
    modelMin: {x: 0, y: 0, z: 0},
    modelMax: {x: 0, y: 0, z: 0},
    modelSize: 0,
    cameraPosition: {x: 0, y: 0, z: 0},
    saveCameraPosition : false,
    setModelPath: (path) => set((state) => ({ modelPath: path })),
    setSceneDimensions: (center, size, min, max) => set((state) => ({ modelCenter: center, modelSize: size, modelMin : min, modelMax : max})),
    saveCameraPisition: () => set((state) => ({ saveCameraPosition: true })),
    setCameraPosition: (position) => set((state) => ({ cameraPosition: position, saveCameraPosition: false })),
    resetCameraPosition: () => set((state) => ({ cameraPosition: {x :0, y:0, z:0} }))
})

export const useEnvironmentStore = create(environmentStore)
export const useSceneStore = create(sceneStore)