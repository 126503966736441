import React from "react";
import './App.css';
import { DemoShowRoomParent } from './showrooms/DemoShowroom';
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { HemShowroomParent } from "./showrooms/HemShowroom";
import MobileViewer from './res/HemShowroom/MobileViewer';
import ShowroomLanding from "./showroom-landing";

const NotFoundPage = () => {

	return (
		<a>Not found</a>
	)
}


function App() {

	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/">
					<ShowroomLanding />
				</Route>
				<Route exact path="/demoshowroom">
					<DemoShowRoomParent />
				</Route>
				<Route exact path="/hemshowroom">
					<HemShowroomParent />
				</Route>
				<Route path="/viewer/:id">
					<MobileViewer/>
				</Route>
				<Route path="*">
					<NotFoundPage />
				</Route>
			</Switch>
		</BrowserRouter>
	);
}

export default App;
