import React from 'react';
import HemLogo from './res/hem.svg';

function ShowroomLanding(props) {
    const VITRA_URL = `/demoshowroom/`;
    const HEM_URL = `/hemshowroom/`;

    const OpenVitra = () => {
        window.open(VITRA_URL, "_blank");
    };

    const OpenHem = () => {
        window.open(HEM_URL, "_blank");
    };

    return (
        <div className="bg-navblack h-screen">
            <div className="max-w-7xl bg-navblack mx-auto h-full">
                <div className="max-w-7xl text-center flex flex-col justify-center items-center bg-navblack text-white pt-12 px-5 h-full">
                    <div>
                        <h2 className="font-bodytext text-6xl leading-tight tracking-tighter max-w-mw820 md:text-size64">
                            Bringing showrooms into <br />
                            <span className="text-buttonyellow mt-msmall">your </span>
                            <span className="relative bg-buttonyellow text-white inline-block rounded-br40 px-4 mt-msmall ">
                                browser
                                <div className="browser">
                                    <div className="bg-divpink"></div>
                                    <div className="bg-divyellow"></div>
                                    <div className="bg-spangreen"></div>
                                </div>
                            </span>
                        </h2>
                    </div>

                    <div className="flex flex-col lmd:flex-row justify-center items-center mt-20">
                        <div className="box-border bg-gray-800 w-60 h-30 sm:w-100 sm:h-50 flex justify-center items-center hover:bg-gray-600" onClick={OpenVitra}>
                            <svg className="w-auto h-18 sm:h-30" viewBox="0 0 91.928 30">
                                <path fill="#fff" d="M0 11.18h7.255l4.275 9.183 4.486-9.183h7.149l-9.463 17.979h-4.38L0 11.18zm22.991-6.799C22.991 1.962 24.954 0 27.407 0c2.418 0 4.346 1.962 4.346 4.381s-1.928 4.416-4.346 4.416a4.42 4.42 0 0 1-4.416-4.416zm.981 24.778V11.18h6.729v17.979h-6.729zM34.241 5.573h6.519v5.607h3.855v5.152H40.76v12.827h-6.589V16.367h-1.928V11.18h1.963l.035-5.607zm12.055 5.607h6.729l-.105 2.523s1.752-2.979 5.993-2.979c.245 0 .491 0 .736.035v6.099c-.524-.245-1.227-.386-1.962-.386-2.173 0-4.767 1.051-4.767 3.26v9.427h-6.624V11.18zm22.431-.596c2.278 0 4.486.912 6.064 2.489V11.18h6.062v17.979h-6.167s-.036-.56-.036-1.156c0-.279 0-.561.036-.806-1.473 1.822-3.681 2.698-5.959 2.698-5.677 0-9.042-4.977-9.042-9.708 0-4.276 2.769-8.341 7.184-9.392a8.352 8.352 0 0 1 1.858-.211zM75 20.257c0-2.417-1.962-4.346-4.381-4.346-2.418 0-4.451 1.928-4.451 4.346s2.033 4.452 4.451 4.452C73.038 24.708 75 22.675 75 20.257zm8.131 5.398c0-2.488 1.927-4.451 4.416-4.451 2.418 0 4.381 1.963 4.381 4.451 0 2.383-1.963 4.346-4.381 4.346-2.489-.001-4.416-1.963-4.416-4.346z"></path>
                            </svg>
                        </div>
                        <div className="box-border bg-gray-800 w-60 h-30 sm:w-100 sm:h-50 flex justify-center items-center ml-0 mt-10 lmd:ml-10 lmd:mt-0 hover:bg-gray-600" onClick={OpenHem}>
                            <img className="w-auto h-18 sm:h-30" src={HemLogo} alt="hem" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShowroomLanding;