import { useStore } from "../utils/Store";
import React from "react";
import {resource} from '../res/resource';
import QRCode from 'react-qr-code';

const Menu = (props) => {

    const currentURL = window.location.href.split('/').slice(0,-2).join('/') + '/viewer/'

    let platform = window.navigator.platform;
    let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    let os = null;
  
    if (/iPhone/g.test(navigator.userAgent)) {
      os = 'iphone';
    }
    else if (/iPad/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      os = 'ipad';
    }
    else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'windows';
    }
    else if (/android/i.test(navigator.userAgent)) {
      os = 'android';
    }
    else {
      os = 'device';
    }

    let glbPath;
    let usdzPath;

    const selectedObject = useStore(state => state.selectedObject);
    const setSelectedObjectName = useStore(state => state.setSelectedObjectName);

    if(selectedObject.name !== "")
    {
        resource.map(object => {
            if(object.name === selectedObject.name) {
                glbPath = object.glbPath;
                usdzPath = object.usdzPath;
            }
        })
    }

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })

        }

        window.addEventListener('resize', handleResize)
    })

    let left = 20;
    if(dimensions.width >= 640){
        left = 50;
    }

    if(selectedObject.name == ""){
        return null;
    }

    return (
        <>
            <div style={{width:'90%',zIndex: 1000, position: 'absolute', bottom: `70px`, marginLeft: `${left}px`,marginRight:`${left}px`,display:'grid',gridTemplateColumns:'1fr 1fr 3fr 1fr'}}>
                <div style={{alignSelf:'end',width:'fit-content'}}>
                    <p style={{whiteSpace:'nowrap'}} className="product-name"> {props.data[selectedObject.name].name} </p>
                </div>
                <div style={{gridRow:'span 2',justifySelf:'center'}}>
                    { ((os === 'windows' || os=== 'device') && props.qrCodeShow) && <div className="flex justify-center">
                    <QRCode
                    value={`${currentURL}${selectedObject.name}`}
                    size={100}
                    level={"L"}
                    />
                    </div>}
                </div>
                <div>
                </div>
                <div style={{alignSelf:'end',justifySelf:'end'}}>
                    <p className="product-sub-name"> {props.data[selectedObject.name].price} </p>
                </div>
                <div style={{width:'fit-content'}}>
                    <hr class="dashed"></hr>
                    <p style={{whiteSpace:'nowrap'}} className="product-description"> {props.data[selectedObject.name].sub_name} </p>
                </div>
                <div>  
                </div>
                <div style={{justifySelf:'end'}}>
                    <a className="chip" href={props.data[selectedObject.name].link} target="_blank">Purchase</a>
                </div>
                <div></div>
                <div style={{alignSelf:'start',justifySelf:'center',fontSize:'18px',fontWeight:'500',color:'white'}}>{ ((os === 'windows' || os=== 'device') && props.qrCodeShow) && `Scan to See in AR`}</div>
                <div></div>
                <div></div>
            </div>

            <div className="close-button" style={{ zIndex: 1000, position: 'absolute', top: `20px`, right: `20px`, borderRadius: '24px', display: "flex", alignItems: "center" }} onClick={() => setSelectedObjectName("")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 492 492" className="svgs">
                    <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872
               c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872
               c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052
               L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116
               c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952
               c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116
               c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                </svg>
            </div>
        </>
    )
}

export { Menu }