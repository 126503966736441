import React, { useEffect, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three'
import { useStore } from './Store';
import { Vector3 } from 'three';
import { OrbitControls } from '@react-three/drei';

const Target = React.forwardRef((props, ref) => {

    return (
        <mesh ref={ref}>
            <cylinderBufferGeometry attach="geometry" args={[0.1, 0.1, 0.01, 32]} />
            <meshBasicMaterial transparent opacity={0.5} color={'white'} />
        </mesh>
    )
})

const CameraControls = (props) => {

    const cameraControls = useRef();
    const target = useRef();
    const targetPosition = [0, 0, 0];
    var targetCameraPosition = new THREE.Vector3();
    var targetVisibility = false;
    var orbitControlsStartTime = 0;
    var time = 0;
    var isCameraAnimating = false;
    var supportsTouch = ('ontouchstart' in window) || navigator.msMaxTouchPoints;

    const {
        camera,
        gl: { domElement }
    } = useThree();

    const selectedObject = useStore(state => state.selectedObject);
    const isObjectInteracting = (selectedObject.name !== "");

    var autoRotateTimeout = null

    const updateCameraOrbit = () => {
        // Update OrbitControls target to a point just in front of the camera

        const forward = new THREE.Vector3();
        camera.getWorldDirection(forward);

        cameraControls.current.target.copy(camera.position).add(forward.multiplyScalar(0.1));

        cameraControls.current.minPolarAngle = Math.PI * 0.1;
        cameraControls.current.maxPolarAngle = Math.PI * 0.9;
    };

    useFrame((state) => {
        time = state.clock.getElapsedTime();
        //cameraControls.current.update();
        target.current.position.fromArray(targetPosition);
        target.current.visible = targetVisibility;

        if (isCameraAnimating) {
            camera.position.lerp(targetCameraPosition, 0.05);

            if (!isObjectInteracting) {
                updateCameraOrbit();
                if (Math.abs(targetCameraPosition.distanceTo(camera.position)) < 0.05) {
                    isCameraAnimating = false;
                    targetVisibility = true;
                }
            } else {
                if (Math.abs(targetCameraPosition.distanceTo(camera.position)) < 0.4) {
                    isCameraAnimating = false;
                }
            }
        }
    })

    useEffect(() => {
        camera.position.set(1.5, 1.3, 3.0);
        camera.fov = 54;
        camera.updateProjectionMatrix();
        updateCameraOrbit();
    }, [])

    useEffect(() => {

        if (selectedObject.name === "") {
            targetCameraPosition = camera.position.clone();
            targetCameraPosition.y = 1.3;
            isCameraAnimating = true;

            clearTimeout(autoRotateTimeout);
            cameraControls.current.autoRotate = false;

            updateCameraOrbit();
        } else if (selectedObject.selectedObjectSize > 0) {

            var center = new Vector3(selectedObject.selectedObjectCenter[0], selectedObject.selectedObjectCenter[1], selectedObject.selectedObjectCenter[2]);
            var dir = camera.position.clone().sub(center).normalize().multiplyScalar(selectedObject.selectedObjectSize * 0.5);
            var target = center.clone().add(dir);

            cameraControls.current.target.set(selectedObject.selectedObjectCenter[0],
                selectedObject.selectedObjectCenter[1], selectedObject.selectedObjectCenter[2]);

            targetCameraPosition = target;
            isCameraAnimating = true;

            cameraControls.current.autoRotate = true;

            cameraControls.current.target.set(selectedObject.selectedObjectCenter[0],
                selectedObject.selectedObjectCenter[1], selectedObject.selectedObjectCenter[2]);
    
            cameraControls.current.minPolarAngle = Math.PI * 0.1;
            cameraControls.current.maxPolarAngle = Math.PI * 0.5;
        }

    })

    useEffect(() => {

        clearTimeout(autoRotateTimeout);

        const endAction = () => {

            if (isCameraAnimating) return;

            //updateCameraOrbit();
            targetVisibility = true;

            if (isObjectInteracting) {
                clearTimeout(autoRotateTimeout);
                autoRotateTimeout = setTimeout(() => {
                    cameraControls.current.autoRotate = true;
                }, 3000)
            }
        }

        const startAction = () => {

            if (isCameraAnimating) return;

            orbitControlsStartTime = time;
            targetVisibility = false;
            cameraControls.current.autoRotate = false;
        }

        const changeAction = () => {
            targetVisibility = false;
        }

        cameraControls.current.addEventListener('end', endAction);
        cameraControls.current.addEventListener('start', startAction);
        cameraControls.current.addEventListener('change', changeAction);

        return () => {
            // Remove event listners here
            cameraControls.current.removeEventListener('end', endAction);
            cameraControls.current.removeEventListener('start', startAction);
            cameraControls.current.removeEventListener('change', changeAction);
            clearTimeout(autoRotateTimeout);
        }
    });

    const onFloorClicked = (e) => {

        if (isObjectInteracting || isCameraAnimating || e.intersections.length > 1) {
            targetVisibility = false;
            return;
        }

        const position = e.intersections[0].point;
        targetCameraPosition = position.clone();
        targetCameraPosition.y = camera.position.y;

        if (time - orbitControlsStartTime < 0.4) {
            targetVisibility = false;
            isCameraAnimating = true;
        }
    }

    const onFloorHovred = (e) => {

        if (!isObjectInteracting) {
            if (e.intersections.length === 1) {
                const position = e.intersections[0].point;
                position.toArray(targetPosition);
                targetVisibility = true;
            } else {
                targetVisibility = false;
            }
        }
    }
    const onPointerEntersFloor = (e) => {
        if (!isObjectInteracting) {
            targetVisibility = true;
        }
    }

    const onPointerLeavesFloor = (e) => {
        if (!isObjectInteracting) {
            targetVisibility = false;
        }
    }



    return (
        <>
            <OrbitControls ref={cameraControls}
                enableDamping={true} dampingFactor={0.2}
                enableZoom={false}
                rotateSpeed={0.2}
                camera={camera}/>
            <primitive object={props.navMesh} visible={false} dispose={null}
                onClick={!supportsTouch ? onFloorClicked : undefined}
                onPointerDown={supportsTouch ? onFloorClicked : undefined}
                onPointerMove={onFloorHovred}
                onPointerEnter={onPointerEntersFloor}
                onPointerLeave={onPointerLeavesFloor} />
            <Target ref={target} />
        </>

    )
}

export { CameraControls }