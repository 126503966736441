import React from 'react'
import { useLoader, useThree } from '@react-three/fiber';
import * as THREE from 'three'
import { useEnvironmentStore } from './Store';
import { Environments } from './Consts';
import { RGBELoader } from './RGBELoader';

const SkyBox = () => {
    const { gl, scene } = useThree();

    const envMap = useEnvironmentStore((state) => state.environmentMap)
    const ambientIntensity = useEnvironmentStore((state) => state.ambientIntensity)
    const environmentMapExplicitPath = useEnvironmentStore((state) => state.environmentMapExplicitPath)

    var envMapPath = Environments[envMap] ? Environments[envMap].HDR : "";
    envMapPath = environmentMapExplicitPath ? environmentMapExplicitPath : envMapPath;

    const hdrEquirect = useLoader(RGBELoader, envMapPath);

    if (envMapPath) {
        const pmremGenerator = new THREE.PMREMGenerator(gl);
        pmremGenerator.compileEquirectangularShader();

        const hdrCubeRenderTarget = pmremGenerator.fromEquirectangular(hdrEquirect);
        hdrEquirect.dispose();
        pmremGenerator.dispose();

        scene.environment = hdrCubeRenderTarget.texture;
        //scene.background = hdrCubeRenderTarget.texture;
    }

    return (
        <ambientLight intensity={ambientIntensity}/>
    )
};

export { SkyBox }