import { useRef } from "react"
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from 'three'
import { useStore } from "./Store";
import data from '../res/DemoShowroom/data.json';
import { Flex, Box } from '@react-three/flex'

const SelectableObject = (props) => {

    const objectRef = useRef();
    const selectedObject = useStore(state => state.selectedObject);
    const setSelectedObject = useStore(state => state.setSelectedObject);

    const endColor = new THREE.Color('black');
    const isSelected = (selectedObject.name === props.object.name);
    var supportsTouch = ('ontouchstart' in window) || navigator.msMaxTouchPoints;

    useFrame(() => {
        if (objectRef.current.material.emissive.r + objectRef.current.material.emissive.g + objectRef.current.material.emissive.b > 0.03) {
            objectRef.current.material.emissive.lerp(endColor, 0.09);
        }
    })

    const onPointerEnterObject = () => {

        if (selectedObject.name !== "") {
            return;
        }

        objectRef.current.material.emissive = new THREE.Color(0xc3f0c);
    }

    const onObjectClicked = () => {

        if (selectedObject.name !== "") {
            return;
        }

        const bb = objectRef.current.geometry.boundingBox;

        var max = bb.max.clone();
        var min = bb.min.clone();

        objectRef.current.localToWorld(max);
        objectRef.current.localToWorld(min);

        var center = new THREE.Vector3();

        center.x = (max.x + min.x) / 2;
        center.y = (max.y + min.y) / 2;
        center.z = (max.z + min.z) / 2;

        const size = max.distanceTo(min);

        setSelectedObject(objectRef.current.name, [center.x, center.y, center.z], size)
    }

    const objectData = data[props.object.name];
    var optionsList = [];

    if (objectData) {
        optionsList = objectData.materials.map((materialName, index) => {
            return (
                <Box key={materialName} centerAnchor padding={1.5}>
                    <mesh material={props.materials[materialName]}
                        onPointerUp={() => {
                            objectRef.current.material = props.materials[materialName];
                        }}>
                        <sphereGeometry attach="geometry" args={[1, 16, 16]} />
                    </mesh>
                </Box>
            )
        })
    }

    return (
        <primitive ref={objectRef} object={props.object}
            onPointerEnter={onPointerEnterObject}
            onClick={!supportsTouch ? onObjectClicked : undefined}
            onPointerDown={supportsTouch ? onObjectClicked : undefined}>
            {isSelected &&
                <Flex position={[0, 9, 0]} justifyContent="center" alignItems="center" flexDirection="row">
                    {optionsList}
                </Flex>
            }

        </primitive>
    )
}

export { SelectableObject }