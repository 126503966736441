import React from 'react'
import { CameraControls } from '../utils/CameraControls'
import { WaterMesh } from '../utils/Water'
import { useGLTF } from '@react-three/drei'
import { useEquirectangularHDR } from '../utils/SkyBox'
import aoMapPath from '../res/HemShowroom/aoMap.png'
import lightMapPath from '../res/HemShowroom/lightMap.png'
import { useTexture } from '@react-three/drei'
import { SelectableObject } from '../utils/SelectableObject'
import * as THREE from 'three';
import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber'
import { Menu } from '../hud/Menu';
import { Loader } from '../hud/Loader';
import data from '../res/HemShowroom/data.json';

const HemShowroomParent = () => {
    const qrCodeShow=true;

	return (
		<>
			<Menu data={data} qrCodeShow={qrCodeShow}/>
			<Canvas style={{ height: '100vh', width: '100vw', touchAction: 'none' }}
				dpr={window.devicePixelRatio}>
				<Suspense fallback={<Loader />}>
					<HemShowroom />
				</Suspense>
			</Canvas>
		</>
	)
}


const HemShowroom = () => {
    const { scene, nodes, animations, materials } = useGLTF('/HemShowroom/Showroom.glb')
    const skybox = useEquirectangularHDR();
    const aoMap = useTexture(aoMapPath);
    const lightMap = useTexture(lightMapPath);
    lightMap.flipY = false;
    aoMap.flipY = false;

    //console.log(nodes);
    // console.log(materials);
    scene.children[0].remove(nodes.navigation);
    scene.children[0].remove(nodes.VlinderSofa);
    scene.children[0].remove(nodes.Resting_Bear);
    scene.children[0].remove(nodes.Resting_Bear1);
    scene.children[0].remove(nodes.pool);

    // Transparetn materials
    nodes.Glass.material.transparent = true;
    nodes.Pothos.material.transparent = true;
    nodes.FloorEX.material.normalScale = new THREE.Vector2(10,10);
    
    nodes.floor.material.normalScale = new THREE.Vector2(0.5, 0.5);
    nodes.floor.material.roughness = 0.0
    nodes.floor.material.metalness = 0.4
    nodes.floor.material.clearCoat = 1.0
    nodes.floor.material.lightMapIntensity = 2.0

    // Set Render orkder
    nodes.sun.renderOrder = -6;
    nodes.world.renderOrder = -5;
    nodes.cloud.renderOrder = -4;
    nodes.see.renderOrder = -3;
    nodes.poolbotom.renderOrder = -2;
    nodes.pool.renderOrder = -1;

    for (var key in materials) {
        const material = materials[key];

        if (material) {
            material.envMap = skybox;
            material.aoMap = aoMap;
            material.lightMap = lightMap;
        }
    }

    nodes.world.material = new THREE.MeshBasicMaterial({map: nodes.world.material.map});
    nodes.see.material = new THREE.MeshBasicMaterial({map: nodes.see.material.map});
    nodes.sun.material = new THREE.MeshBasicMaterial({map: nodes.sun.material.map, transparent: true});
    nodes.cloud.material = new THREE.MeshBasicMaterial({map: nodes.cloud.material.map, transparent: true});
    nodes.pool.material = new THREE.MeshBasicMaterial({map: nodes.pool.material.map, transparent: true});

    return (
        <group>
            {/* <ambientLight /> */}
            <directionalLight position={[-3.4, 7.27, -8.34]} intensity={0.05}/>
            <primitive object={scene} rotation={[0, 0, 0]}/>
            <SelectableObject object={nodes.VlinderSofa} materials={materials} />
            <SelectableObject object={nodes.Resting_Bear} materials={materials}/>
            <SelectableObject object={nodes.Resting_Bear1} materials={materials}/>
            <SelectableObject object={nodes.Table_Items} materials={materials} />
            <SelectableObject object={nodes.Plate_Table_Top} materials={materials} />
            <SelectableObject object={nodes.Tabale_Top} materials={materials} />
            <WaterMesh waterMesh={nodes.pool}/>
            <CameraControls navMesh={nodes.navigation}></CameraControls>
        </group>
    )
}

export { HemShowroomParent }