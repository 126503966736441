import { useTexture } from '@react-three/drei';
import { useEffect, useRef } from 'react';
import { extend, useFrame } from '@react-three/fiber';
import * as THREE from 'three'
import waterNormalPath from '../res/DemoShowroom/waterNormal.jpg'


const WaterMesh = (props) => {

    const water = useRef();
    const waterNormalMap = useTexture(waterNormalPath);
    waterNormalMap.wrapS = THREE.RepeatWrapping;
    waterNormalMap.wrapT = THREE.RepeatWrapping;

    useEffect(()=> {
        water.current.material.transparent = true;
        water.current.material.opacity = 0.75;
        water.current.material.roughness = 0.1;
        water.current.material.metalness = 0.5;
        water.current.material.normalMap = waterNormalMap;
    }, []);


    return(
        <primitive ref={water} object={props.waterMesh} dispose={null}/>        
    )
}

export { WaterMesh }