import React, { useEffect, Suspense, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { useEnvironmentStore } from "./Store";
import { Background } from "./Background";
import { Model } from "./Model";
import { Camera } from "./Camera";
import { SkyBox } from "./Skybox";
import { useSceneStore } from "./Store";
import { Html } from "@react-three/drei";
import { Shadows } from "./Shadows";
import { Environments } from "./Consts";

export const OGMOCanvas = ({ designId, glb_link, usdz_link }) => {

  const modelPath = useSceneStore((state) => state.modelPath);

  if (typeof window !== "undefined") {
    var dpr = window.devicePixelRatio;
  }

  return (
    <Canvas /*style={{ height: '100vh', width: '100vw' }}*/ shadows dpr={dpr}>
      {/* <pointLight position={[10, 10, 10]} /> */}
      <Suspense fallback={<Html> Loading... </Html>}>
        <group>{modelPath && <Model path={modelPath} />}</group>
        <SkyBox />
      </Suspense>
      <Camera />
      <Shadows />
      <Background />
    </Canvas>
  );
};

export { useEnvironmentStore };
export { useSceneStore };
export { Environments };
