import create from 'zustand'

const useStore = create(set => ({
    selectedObject: {
        name: "",
        selectedObjectCenter: [0, 0, 0],
        selectedObjectSize: -1
    },
    setSelectedObject: (id, center, size) => set(state => ({
        selectedObject: {
            name: id,
            selectedObjectCenter: center,
            selectedObjectSize: size
        }
    })),
    setSelectedObjectName: (id) => set(state => ({
        selectedObject: {
            name: id,
            selectedObjectCenter: state.selectedObject.selectedObjectCenter,
            selectedObjectSize: state.selectedObject.selectedObjectSize
        }
    }))
}))

export { useStore }